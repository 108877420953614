import React from 'react';
import style from '../index.module.scss';

const pic1 = require('../../../assets/img/about/dialog.png');
const pic2 = require('../../../assets/img/about/pic5.png');

const Dialog = () => {
  return (
    <div className={style.dialogWrapper}>
      <div className={style.title}>#无障碍#一起走进残障者，一次深度的对话</div>
      <div className={style.time}>2019-11-22</div>
      <div className={style.content}>
        <p>
          很多小米的同学可能已经知道了，在我们的用户中其实有很多残障伙伴。MIUI从2013年开始，率先针对视障用户进行了无障碍优化，还组建了一个虚拟小组负责相关工作，我目前是负责和用户交朋友的那名运营同学。经过小伙伴们的不断努力，去年年底凤凰网科技的手机无障碍测评中，小米是安卓手机第一名——但那会我对无障碍还一无所知！
        </p>
        <p>
          今年年初接手这项工作的时候，说实话真是一脸懵——从小到大，我都没有见过视障者和听障者，肢体障碍者也不多见，我要怎么和他们沟通？会不会冒犯他们？毕竟闭上眼睛，并不足以想象他们的世界，而无障碍的需求也远不止视障用户。疯狂恶补了前任运营薛康同学总结的经验，也和用户们在用户群里聊天，渐渐发现，残障用户也和我们一样嘛，闲聊吐槽抢红包，表情包发得比我还利索……渐渐地我又开始好奇，那他们是怎么生活怎么长大怎么交朋友的？他们谈恋爱吗？他们逛街吗？旅游吗？除了现有的功能，他们还需要些什么呢？问题蜂拥而来……
        </p>
        <p>
          6月21日，米HR上发布了#喊你来看片#
          这部纪录片拍了6年，就是为了让你“看见”真实的残障者，
          真是好羡慕北京的同学们呀……在联络了发起放映的公关部负责企业社会责任的同事后，她答应要在南京放一场。终于，在MIUI11稳定版如期发布后，这次放映如愿以偿，如期而至。
        </p>
        <p>
          11月14日晚上，南京研发中心中华门会议室里座无虚席，来自互一、互四、测试部等各部门的同学50多位同学放下了手头的工作，和我们一起共度了3个小时慢下来的暖心时光。
        </p>
        <p>
          拍摄这部影片的导演之一苏青，片中的听障小女孩李聪、通过普通高考在南京读计算机专业的米粉吴凡、为小米小爱同学提供语音标注的残障就业合作伙伴其实咨询创始人傅高山也来到了现场。
        </p>
        <p>下面我想和大家分享一下令我印象深刻的几件小事： </p>
        <p className={style.subtitle}>慢下来，走近去</p>
        <p>
          《梧桐树》全片时长118分钟，导演的镜头很沉静，没有狗血的剧情，没有血泪的控诉，只是安安静静地记录了孩子们做操、上课、背书、小矛盾、小顽皮等等。我们仿佛用118分钟，陪伴了孩子们6年的时光，随着时间流转，我们看到了唱美声的王怡文，唱豫剧学播音的张丹彤，养乌龟和兔子的李聪，视力和听力都有障碍却依然对懵懂的感情充满好奇的曹寒子，以及会用空鼓诉说梦境的吴梦飞，感受她们的梦想、渴望和现实之间的微妙张力。
        </p>
        <p>
          在观影的过程中我会感觉到突然间世界就慢了下来，不再是我们如火如荼的工作节奏，当我们渐渐融入到影片的情境中，去感受孩子们的所经历的喜怒哀乐，她们不再是1800万盲人或2000万聋人这样的数字，而是和我们一样在生活中跌跌撞撞往前走的人。她们知道自己身体有障碍，需要更努力，这种坚强是只有自己给予。这种精神力量会打动和鼓舞到我们身边每一个人。
        </p>
        <p className={style.subtitle}>深度对话，发现宝藏男孩女孩</p>
        <p>
          影片结束后，大家都还沉浸在孩子们的世界里无法自拔。导演苏青给我们讲述了纪录片创作的背景：这部影片是他和搭档米娜拍摄的第三部关于残障者的影片，没有任何投资方，大部分的拍摄由他和米娜一起完成，后期有越来越多的志愿者加入进来，参与了摄影和后制。他说，“这部影片是在用6年的时间陪伴这群孩子成长，花时间陪伴，哪怕什么也不做，慢慢的就互相了解和接受了。影片想要传达是，耐心的陪伴是交流中最重要的环节。”
        </p>
        <p>
          影片中的听障主人公李聪，今年读高三。她喜欢养小动物，也喜欢画画，刚做了耳蜗手术，正在进行语言康复训练。
        </p>
        <p>
          在南京读大学的吴凡，则是资深米粉。他拥有手机、手环、小爱音箱等小米设备，他说之所以成为米粉，正是因为小米一直保持和用户的直接沟通，踏踏实实地开发盲人真正需要的功能，不凭借对盲人的想象开发一个“盲人专用”的产品，也不做一些看起来花哨但其实没啥用的功能。
        </p>
        <p>
          其实咨询的傅高山则自称为“来自供应链”的朋友。他是中国最早接受BBC培训的残障意识培训师、残障媒体人，如今在探索残障者真实体面的规模化就业可能性。
        </p>
        <p>
          随着人工智能技术的兴起和发展，标注工作成为了残障者们可以尝试的新就业领域，图片标注的工作适合肢体障碍者，因为比较好理解，需求量也大，很快成为重度肢残者就业的一个机会。“但当我们开始想要尝试让盲人进行语音标注的时候，很难有企业愿意尝试，小米是第一家，也是目前唯一一家。”据说目前我们视障伙伴的标注效率和质量都还不错。
        </p>
        <img
          src={pic1}
          alt="图片 深度对话 吴凡讲述自己的经历"
          style={{ width: '50%', marginLeft: '25%' }}
        />
        <p>
          随着沟通越来越深入，我米的同学们打开了话匣子，小米无障碍项目团队表示，一直在思考如何挖掘更深层的角度去聚焦残障群体的产品需求，给残障用户带去更优质的体验。来自电视团队的小伙伴也表示，电视也在快马加鞭，争取突破电视无障碍从无到有的飞跃，他说，“今天看完这个电影以后，心里特别有感触。无障碍不再是一个列表上的需求，不再是一个数字，而是这个坐在我对面的用户，一个真实的人。这让我们知道，我们熬的夜，加的班，都是有意义的，因为真的有人在期待这些功能。”
        </p>
        <p>
          分享后，李聪的妈妈告诉我们，李聪和她的哥哥都是小米的忠实粉丝，她的红米手机用了好多年了，正打算换一台新的，“和你们的工程师们聊完，我们决定以后就买小米了。”（我们真的不是为了带货才请你们来的，真的！）
        </p>
        <img src={pic2} alt="图片 走进残障者的一次深度对话" />
        <p className={style.subtitle}>吐槽也能带来意外惊喜</p>
        <p>
          送嘉宾返程的时候，我们在办公室楼下等车。闲聊间，高山说他们办公室的盲人小伙伴们都特别喜欢上有品APP淘各种好吃的好玩的东西，西红柿鸡蛋面都已经买了好几箱，但因为有品的无障碍体验实在是不够友好，每次都只能拜托明眼人朋友帮忙……
        </p>
        <p>
          刚下班也在等车的有品同学碰巧听到了这段吐槽，作为我米的工程师，用户的吐槽就是战斗的号角，他们立刻承诺愿意进行优化。
        </p>
        <p>
          随着小米的产品越来越多，有品、商城、米家APP、手表等，都在为我们的用户提供越来越多价格厚道，感动人心的好产品，而在做好无障碍优化的前提下，这些产品也必将为残障者的生活带来更美好的体验。我们始终坚信，小米会让每个人享受到科技带来的美好生活，而这美好的前提是，我们能够在做产品的时候意识到，是否存在不方便看、说以及动手的场景，还有很多需要我们去考虑到的需求，而正因为小米愿意与用户交朋友，倾听用户的声音，他们也对小米充满了期待。
        </p>
        <p>
          如果有对做好无障碍有兴趣的小伙伴们，也可以和我们联系哦，我们十分、非常、无比愿意分享小米系统的无障碍开发经验，让我们一起提升小米产品的无障碍体验吧！{' '}
        </p>
      </div>
    </div>
  );
};

export default Dialog;
