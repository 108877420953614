/* eslint-disable no-underscore-dangle */

export function getReferrer() {
  const url = new URL(document.URL);
  const search = new URLSearchParams(url.search);
  return search.getAll('origin')[0];
}

export function getUser() {}

const daDian = {
  // 访问次数
  visitTimes: () => {
    window.onetrack('track', 'view', { tip: '44.2.2.1.564' });
    // window._hmt.push(['_trackEvent', '', '访问次数', '无']);
  },
  // 访问来源
  origin: () => {
    const ref = getReferrer();
    if (ref) {
      window.onetrack('track', 'view', { tip: '44.2.2.1.561', origin: ref });
      //   window._hmt.push(['_trackEvent', '首页', '访问来源', ref]);
    }
  },
  //   // 访问人数
  //   visitPeoples: () => {
  //     window.onetrack('track', 'view', { tip: '44.2.2.1.563' });
  //     window._hmt.push(['_trackEvent', '首页', '访问人数', '无']);
  //   },
  // 导航点击 - 首页
  navHome: () => {
    window.onetrack('track', 'click', { tip: '44.1.2.1.609' });
  },
  // 导航点击 - 了解我们
  navAbout: () => {
    window.onetrack('track', 'click', { tip: '44.1.1.1.553' });
    // window._hmt.push(['_trackEvent', '任意页面', '导航点击-了解我们', '无']);
  },
  // 导航点击 - MIUI
  navMiui: () => {
    window.onetrack('track', 'click', { tip: '44.1.1.1.554' });
    // window._hmt.push(['_trackEvent', '任意页面', '导航点击-MIUI', '无']);
  },
  // 了解我们 - 点击建议
  aboutSuggestion: () => {
    window.onetrack('track', 'click', { tip: '44.3.1.1.556' });
    // window._hmt.push(['_trackEvent', '了解我们页面', '点击-建议', '']);
  },
  // 了解我们 - 点击特别
  aboutSpecial: () => {
    window.onetrack('track', 'click', { tip: '44.3.2.1.557' });
    // window._hmt.push(['_trackEvent', '了解我们页面', '点击-特别', '']);
  },
  // 了解我们 - 点击联合国
  aboutUnion: () => {
    window.onetrack('track', 'click', { tip: '44.3.3.1.558' });
    // window._hmt.push(['_trackEvent', '了解我们页面', '点击-联合国', '']);
  },
  // 了解我们 - 点击夏令营
  aboutSummer: () => {
    window.onetrack('track', 'click', { tip: '44.3.4.1.559' });
    // window._hmt.push(['_trackEvent', '了解我们页面', '点击-夏令营', '']);
  },
  // 了解我们 - 点击对话
  aboutDialog: () => {
    window.onetrack('track', 'click', { tip: '44.3.5.1.560' });
    // window._hmt.push(['_trackEvent', '了解我们页面', '点击-对话', '']);
  },
  // 首页 - 小爱视频播放
  videoXiao: () => {
    window.onetrack('track', 'click', { tip: '44.2.1.1.555' });
    // window._hmt.push(['_trackEvent', '首页', '视频播放', '']);
  },
};
setTimeout(() => {
  daDian.visitTimes();
  daDian.origin();
}, 100);

export default daDian;
