import React from 'react';
import PicWall from '../picWall';
import GoogleSupport from '../googleSupport';
import XmListen from '../xmListen';
import style from './index.module.scss';

const data = require('../data.json');

const { middle } = data;
const MiddleContent = () => {
  return (
    <div className={style.middleWrapper}>
      <PicWall />
      <GoogleSupport data={middle.googleSupport} />
      <XmListen data={middle.xmListen} />
    </div>
  );
};

export default MiddleContent;
