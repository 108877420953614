import React, { useRef, useState } from 'react';
import useObserver from '../../../util';
import style from './index.module.scss';

const google = require('../../../assets/img/homepage/google-support.png');
const sceneOne = require('../../../assets/img/homepage/scene_one.png');
const sceneTwo = require('../../../assets/img/homepage/scene_two.png');
const sceneThree = require('../../../assets/img/homepage/scene_three.png');
const sceneFour = require('../../../assets/img/homepage/scene_four.png');
const color = require('../../../assets/img/homepage/color.png');
const menu = require('../../../assets/img/homepage/menu.png');
const actionLeft = require('../../../assets/img/homepage/action_left.png');
const actionRight = require('../../../assets/img/homepage/action_right.png');
const remoteCtrl = require('../../../assets/img/homepage/remote.png');
const talkback = require('../../../assets/img/homepage/talkback.png');

const GoogleSupport = (props) => {
  const { data } = props;
  const topRef = useRef(null);
  const sceneRef = useRef(null);
  const operatorRef = useRef(null);
  const itemOneRef = useRef(null);
  const itemTwoRef = useRef(null);
  const itemThreeRef = useRef(null);
  const [topArrived, setTopArrived] = useState(false);
  const [sceneArrived, setSceneArrived] = useState(false);
  const [operatorArrived, setOperatorArrived] = useState(false);
  const [oneArrived, setOneArrived] = useState(false);
  const [twoArrived, setTwoArrived] = useState(false);
  const [threeArrived, setThreeArrived] = useState(false);
  useObserver(topRef, setTopArrived);
  useObserver(sceneRef, setSceneArrived);
  useObserver(operatorRef, setOperatorArrived);
  useObserver(itemOneRef, setOneArrived);
  useObserver(itemTwoRef, setTwoArrived);
  useObserver(itemThreeRef, setThreeArrived);
  return (
    <div className={style.wrapper}>
      <div className={`${style.top} ${topArrived && style.enter}`} ref={topRef}>
        <div className={style.title}>
          {data[0].title.split(' ').map((item) => (
            <div>{item}</div>
          ))}
        </div>
        <p className={style.introduction}>{data[0].introduction}</p>
        <div className={style.image}>
          <img
            src={google}
            alt="图片 设计师创意设计 一位用户站在手机面前，面对手机的各个模块，在进行选择"
          />
        </div>
      </div>
      {/* 场景提示 */}
      <div
        className={`${style.scene} ${sceneArrived && style.enter}`}
        ref={sceneRef}
      >
        <p className={style.title}>{data[1].title}</p>
        <p className={style.introduction}>{data[1].introduction}</p>
        <div className={style.image}>
          <div className={style.imgItem}>
            <img src={sceneOne} alt="图片 屏幕锁定时主动提示" />
            <p>屏幕锁定时主动提示</p>
          </div>
          <div className={style.imgItem}>
            <img src={sceneTwo} alt="图片 日历选中日期后的主动播报" />
            <p>日历提醒</p>
          </div>
          <div className={style.imgItem}>
            <img src={sceneThree} alt="图片 指南针方向主动播报 " />
            <p>方向主动播报</p>
          </div>
          <div className={style.imgItem}>
            <img src={sceneFour} alt="图片 计算器结果主动播报" />
            <p>计算结果主动提示</p>
          </div>
        </div>
      </div>
      {/* 无缝衔接 */}
      <div
        className={`${style.operator} ${operatorArrived && style.enter}`}
        ref={operatorRef}
      >
        <p className={style.title}>{data[2].title}</p>
        <div className={style.introduction}>
          {data[2].introduction.split(' ').map((item) => (
            <p>{item}</p>
          ))}
        </div>
        <div className={style.image}>
          <img src={actionLeft} alt="图片 接听电话的两套交互 " />
          <img src={actionRight} alt="图片 图片验证码转化为语音验证码" />
        </div>
      </div>
      {/* 色彩校正&无障碍功能菜单 */}
      <div className={style.item_one}>
        <div className={style.item_one} ref={itemOneRef}>
          <div
            className={`${style.item_one_content} ${oneArrived && style.enter}`}
          >
            <div className={style.item_adj}>{data[3].adj_one}</div>
            <div className={style.item_title}>{data[3].title_one}</div>
            <div className={style.item_text}>{data[3].introduction_one}</div>
            <div className={style.item_adj}>{data[3].adj_two}</div>
            <div className={style.item_title}>{data[3].title_two}</div>
            <div className={style.item_text}>{data[3].introduction_two} </div>
          </div>
          <div className={style.item_img}>
            <img
              src={color}
              alt="图片 色彩校正的选择页面"
              style={{ marginRight: '30px' }}
            />
            <img src={menu} alt="图片 无障碍功能菜单页面" />
          </div>
        </div>
      </div>
      {/* 万能遥控器 */}
      <div className={style.item_two} ref={itemTwoRef}>
        <img src={remoteCtrl} alt="贴近生活的手机万能遥控器" />
        <div
          className={`${style.item_two_content} ${twoArrived && style.enter}`}
        >
          <div className={style.item_title}>{data[4].title}</div>
          <div className={style.item_text}>
            {data[4].introduction.split(' ').map((item) => (
              <p>{item}</p>
            ))}
          </div>
        </div>
      </div>
      {/* talkback */}
      <div className={style.item_three} ref={itemThreeRef}>
        <div
          className={`${style.item_three_content} ${
            threeArrived && style.enter
          }`}
        >
          <div className={style.item_title}>{data[5].title}</div>
          <div className={style.item_text}>
            {data[5].introduction.split(' ').map((item) => (
              <div>{item}</div>
            ))}
          </div>
        </div>
        <img src={talkback} alt="全面适配的TalkBack" />
      </div>
    </div>
  );
};

export default GoogleSupport;
