import React from 'react';
import { Link } from 'react-router-dom';
import daDian from '../../../util/daDian';
import style from './index.module.scss';

const pic3 = require('../../../assets/img/about/pic3.png');
const pic4 = require('../../../assets/img/about/pic4.png');
const pic5 = require('../../../assets/img/about/pic5.png');
const pic7 = require('../../../assets/img/about/pic7.png');

const pics = [pic3, pic4, pic5, pic7];
const alts = [
  '图片 管培生体验视障生活 ',
  '图片 十六位大一新生的特别“军训夏令营”',
  '图片 走进残障者的一次深度对话',
  ''
];
const funcs = [daDian.aboutUnion, daDian.aboutSummer, daDian.aboutDialog];
console.log('打点:', funcs);
const SubItem = ({ data }) => {
  return (
    <div className={style.subWrapper}>
      {data.map((item, index) => (
        <div key={item.id} onClick={funcs[index]}>
          <Link to={`/about/${item.id}`} style={{ textDecoration: 'none' }}>
            <img src={pics[index]} alt={alts[index]} />
            <p className={style.title}>{item.title}</p>
            <p className={style.text}>{item.text}</p>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default SubItem;
