import React from 'react';
import { Link } from 'react-router-dom';
import daDian from 'util';
import style from './index.module.scss';

const pic1 = require('../../../assets/img/about/pic1.png');
const pic2 = require('../../../assets/img/about/pic2.png');
const pic3 = require('../../../assets/img/about/pic6.png');

const pics = [pic1, pic2, pic3];
const alts = [
  '图片 第十三届全国人民代表大会照片',
  '图片 特别的爱给特别的520',
  '',
];
const funcs = [daDian.aboutSuggestion, daDian.aboutSpecial];
const MainItem = ({ data }) => {
  return (
    <>
      <div className={style.mainWrapper}>
        {data.map((item, index) => (
          <div key={item.id} onClick={funcs[index]}>
            <Link to={`/about/${item.id}`} style={{ textDecoration: 'none' }}>
              <img src={pics[index]} alt={alts[index]} />
              <p className={style.title}>{item.title}</p>
              <p className={style.text}>{item.text}</p>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default MainItem;
