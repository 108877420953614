/* eslint-disable global-require */
/* eslint-disable no-var */
import React from 'react';
import { NavLink } from 'react-router-dom';
import daDian from '../../util/daDian';
import style from './header.module.scss';

const Header = ({ color }) => {
  let linkStyle = '';
  let spanStyle = '';
  var icon;
  if (color === 'dark') {
    linkStyle = style.dark;
    spanStyle = style.spanDark;
    icon = require('../../assets/img/icon_white.svg');
  } else {
    linkStyle = style.light;
    spanStyle = style.spanLight;
    icon = require('../../assets/img/icon_black.svg');
  }
  return (
    <>
      <div className={style.wrapper}>
        <div className={style.head}>
          <i>
            <img src={icon} alt="" />
          </i>
          <span className={spanStyle}>小米无障碍</span>
          <ul>
            <li>
              <NavLink
                className={linkStyle}
                to="/"
                exact
                activeStyle={{
                  color: '#ffffff',
                }}
                onClick={daDian.navHome}
              >
                首页
              </NavLink>
            </li>
            <li>
              <NavLink
                className={linkStyle}
                to="/about"
                activeStyle={{
                  color: '#000000',
                }}
                onClick={daDian.navAbout}
              >
                了解我们
              </NavLink>
            </li>
            <li>
              <a
                className={linkStyle}
                href="https://hyperos.mi.com/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={daDian.navMiui}
              >
                小米澎湃OS
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Header;
