import React, { useState, useRef } from 'react';
import useObserver from '../../../util';
import style from './index.module.scss';

const data = require('../data.json');
const wall = require('../../../assets/img/homepage/picwall.png');
// const pic1 = require('../../../assets/img/homepage/pic1.png');
// const pic2 = require('../../../assets/img/homepage/pic2.png');
// const pic3 = require('../../../assets/img/homepage/pic3.png');
// const pic4 = require('../../../assets/img/homepage/pic4.png');
// const pic5 = require('../../../assets/img/homepage/pic5.png');
// const pic6 = require('../../../assets/img/homepage/pic6.png');
// const pic7 = require('../../../assets/img/homepage/pic7.png');

const PicWall = () => {
  const topRef = useRef(null);
  const imgRef = useRef(null);
  const [arrived1, setArrived1] = useState(false);
  const [arrived, setArrived] = useState(false);
  useObserver(topRef, setArrived1);
  useObserver(imgRef, setArrived);
  return (
    <>
      <div
        className={style.middleTop + ' ' + (arrived1 && style.topEnter)}
        ref={topRef}
      >
        <p>{data.middle.middleTop.text}</p>
        <h2>{data.middle.middleTop.title}</h2>
        <div>{data.middle.middleTop.content}</div>
      </div>
      <div
        className={style.picWall + ' ' + (arrived && style.imgEnter)}
        ref={imgRef}
      >
        <img
          src={wall}
          alt="小米管培生体验视障生活 下棋"
          className={style.img1}
        />
        {/* <img
          src={pic1}
          alt="小米管培生体验视障生活 下棋"
          style={{ width: '27%' }}
          className={style.img1}
        />
        <img
          src={pic2}
          alt="一加一残障人公益集团 蔡聪演讲"
          style={{ width: '17%' }}
          className={style.img1}
        />
        <img
          src={pic3}
          alt="小米管培生体验视障生活 蒙上双眼"
          style={{ width: '23%' }}
          className={style.img1}
        />
        <img
          src={pic4}
          alt="小米管培生体验视障生活 根据导航指引寻路"
          style={{ width: '28%' }}
          className={style.img1}
        />

        <img
          src={pic5}
          alt="小米管培生体验视障生活 体验通过talkback操作手机"
          style={{ width: '18%', marginLeft: '13%' }}
          className={style.img2}
        />
        <img
          src={pic6}
          alt="小米管培生体验视障生活 杨青教如何使用talkback"
          style={{ width: '36%', marginBottom: '6%' }}
          className={style.img2}
        />
        <img
          src={pic7}
          alt="小米管培生体验视障生活 蒙上双眼"
          style={{ width: '18%' }}
          className={style.img2}
        /> */}
      </div>
    </>
  );
};

export default PicWall;
