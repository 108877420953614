import React from 'react';
import style from '../index.module.scss';

const pic = require('../../../assets/img/about/pic1.png');

const Suggestion = () => {
  return (
    <div className={style.suggestionWrapper}>
      <div className={style.title}>雷军：我的2019年两会建议</div>
      <div className={style.time}>
        2019-03-24
        <br />
        以下文章来源于雷军，作者雷军
      </div>
      <img src={pic} alt="图片 第十三届全国人民代表大会照片" />
      <div className={style.content}>
        <p>
          明天，第十三届全国人民代表大会第二次会议即将开幕。作为全国人大代表，雷军今年准备了三份向大会提交的建议，内容分别是：《关于布局5G应用
          推动物联网创新发展的建议》；《关于提高创新能力
          大力发展商业航天产业的建议》；《关于进一步深化落实信息无障碍建设的建议》。
        </p>
        <p className={style.subtitle}>
          关于布局5G应用 推动物联网创新发展的建议
        </p>
        <p>
          近年来,物联网作为全新的连接方式，呈现突飞猛进的发展态势。据IoT
          Analytics统计，2018年全球物联网设备已经达到70亿台；到2020年，活跃的物联网设备数量预计将增加到100亿台，到2025年将增加到220亿台。全球物联网产业规模由2008年500亿美元增长至2018年近1510亿美元。在中国，物联网的大规模应用与新一轮科技与产业变革融合发展，《2018年中国5G产业与应用发展白皮书》预计，到2025年中国物联网连接数将达到53.8亿，其中5G物联网连接数达到39.3亿。根据前瞻产业研究院发布的相关报告预计，2022年，中国物联网行业市场规模将超过7.24万亿元。
        </p>

        <p>
          5G是数字经济新引擎，产业应用不限于智能手机、基站建设等领域，更会推动物联网、区块链、视频社交、人工智能产品与应用的发展。由于5G技术能满足机器类通信、大规模通信、关键性任务通信对网络速率、稳定性和时延的高要求，因此物联网应用场景十分广泛，尤其与车联网、无人驾驶、超高清视频、智能家居等产业深度融合，进一步应用到制造业、农业、医疗、安全等领域，为各行各业带来新的增长机遇。
        </p>

        <p>
          目前，受制于5G基础建设尚未完成，相关标准尚未明确，5G的服务与应用资源也不够丰富等因素，5G的产业发展仍存在困难与挑战。但是，考虑到5G是各国未来一段时间主要信息基础设施和技术竞争关键领域，对社会经济发展具有较强的拉动作用，建议提前布局5G产业应用，推动5G与物联网的创新融合发展。因此，我建议：
        </p>

        <p>一、加速工业物联网应用，助力工厂智能化转型</p>

        <p>
          全球制造业竞争推动工厂向智能化转型，物联网作为连接人、机器和设备的关键支撑技术受到企业的高度关注。对于产生少量生产数据且对时延不敏感的设备，现有连接技术可以满足其联网改造。但对于时延敏感，高可靠性或产生大量生产数据的设备，传统连接技术已无法满足。在这种情况下，5G技术优势能够较好满足工业控制需求，同时为制造企业提供远程控制和数据流量管理工具，以便更高效智能地管理大量的设备，并通过无线网络对这些设备进行软件更新。
        </p>

        <p>
          建议国家加大对高端装备、智能制造、工业物联网等重点领域的财税金融支持力度，引导中央、地方产业投资基金和社会资本，围绕大型制造企业上下游进行垂直改造，加强自动化产线、无人工厂等重大技术研发和成果转化，打造虚拟的产业闭环，提高产业的生产效率和整体国际竞争力。
        </p>

        <p>二、发展智慧农业，助推“乡村振兴”战略实施</p>

        <p>
          乡村振兴战略是以发展和创新的眼光推进现代农业建设。实施乡村振兴战略，就是推进农业农村的现代化，以创新驱动乡村振兴发展。随着物联网在农业领域的应用越来越广泛，5G技术的应用将为建设智慧农业、数字乡村奠定坚实科技基础，带动农业实现发展变革。
        </p>

        <p>
          建议国家有关部门制定出台5G农业应用补贴和优惠政策，并鼓励社会资本、运营商、互联网企业等共同参与，因地制宜规划打造智慧农业示范区、试验区，并在经验成熟后进行全国推广，全面提升农业领域的高新科技应用程度。例如在养殖业，通过无线传感器网络技术，进行基本信息管理、疾病档案管理、防疫管理、营养繁殖管理，发展智慧养殖；在植保方面，借助物联网技术自动探测和记录区域内的微气候、墒情等环境信息，并结合植物保护专家系统来精确地预测病虫害的发生，从而通过无人机喷洒农药，精准高效解决农业生产的植保问题。
        </p>

        <p>三、发展无人驾驶与车联网，提高交通智能化程度</p>

        <p>
          在5G众多的应用场景中，无人驾驶和车联网被认为是最有可能出现的引爆点——对通信网络有着极高的要求，大带宽、低时延、海量的连接数量、严密的覆盖，这些都是5G技术的核心优势。一方面无人驾驶具有巨大的节能潜力，在减少交通事故、改善拥堵、提高道路及车辆利用率等方面意义深远，并可直接带动智能汽车后市场等产业的快速发展。另一方面全球车联网产业进入快速发展阶段，信息化、智能化引领，全球车联网服务需求逐渐加大。基于5G技术的应用，智能交通领域将快速进入发展上行区间。
        </p>
        <p>
          建议国家研究、制定和出台关于智能交通的中长期发展目标，制定相应的法律法规和行业标准支持产业发展。尤其针对无人驾驶汽车的安全责任问题、技术试验问题、车联网的国家标准规范、智能芯片应用等产业发展关键点进行前置研判，通过鼓励性政策支持交通运输领域智能、安全、可控发展。
        </p>
        <p>四、普及医疗物联网应用，助力“健康中国”建设</p>
        <p>
          物联网技术在医疗行业有很广泛的应用空间。服务患者方面，可以采用LBS技术实现智能导诊，优化就诊流程，还可以借助可穿戴传感器和服务解决方案进行远程护理；保障设备质量方面，可以采用各类专用传感器，跟踪设备使用情况，借助预测性维护来修复关键医疗设备存在的潜在问题，完善设备运维体系；环境监测方面，可以通过传感器对ICU室、手术室等特殊地点进行环境监测和预警。同时,基于医疗护理全流程的健康大数据，在安全保护前提下的数据标准细化、完善,以及数据网络的综合利用也显得尤为迫切。
        </p>
        <p>
          建议国家有关部门逐步推动5G技术在医疗卫生领域的应用，加快完善医疗物联网和健康大数据相关标准，制定医疗智能可穿戴设备及配套信息平台行业标准，出台针对物联网企业在医疗领域投入科学研究、应用开发的鼓励政策，使云计算、人工智能、虚拟现实/增强现实、物联网、区块链等技术在医疗卫生行业更好地集成创新和融合应用，满足人民日益增长的健康医疗新需求。
        </p>
        <p className={style.subtitle}>
          关于提高创新能力&nbsp;大力发展商业航天产业的建议
        </p>
        <p>
          改革开放40年来，中国在航天事业上取得了举世瞩目的成绩。2016年4月24日，首个“中国航天日”，习近平总书记作出重要指示：“探索浩瀚宇宙，发展航天事业，建设航天强国，是我们不懈追求的航天梦。”同年，习总书记在会见天宫二号和神舟十一号载人飞行任务航天员及参研参试人员代表时强调：“在航天事业发展征程上勇攀高峰，努力建设航天强国和世界科技强国。”
        </p>
        <p>
          今年2月20日，习总书记在会见探月工程嫦娥四号任务参研参试人员代表时强调：“实践告诉我们，伟大事业都基于创新。创新决定未来。建设世界科技强国，不是一片坦途，唯有创新才能抢占先机。”习总书记的系列讲话为新时期我国航天事业指明了前进方向。党的十九大报告明确提出了建设航天强国的战略目标，彰显了党和国家对我国航天事业的高度重视。国家“十三五”规划纲要将航空航天列为创新驱动发展的重点领域，为我国由航天大国向航天强国的转型发展提供了良好机遇。
        </p>
        <p>
          航天是当今世界最具挑战性和广泛带动性的高科技领域之一，为服务国家发展大局和增进人类福祉作出了重要贡献。近年，在运载、卫星和空间应用等领域，涌现出太空探索公司（SpaceX）、蓝色起源（Blue
          Origin）、一网（OneWeb）等大批商业航天公司，被认为是最为活跃的创业领域之一。据《The
          SpaceReport
          2018》，2017年全球航天市场规模达到3835亿美元，其中80%为商业航天。商业航天行业规模未来预计可达数万亿美元，将迎来空前的发展机遇，可重复使用火箭、巨型商业星座、商业载人空间站等航天计划，正在逐渐成真，彰显出商业航天推进技术进步和产业发展的巨大力量。
        </p>
        <p>
          从国家来看，美国的商业航天发展最为全面完善，从顶层的立法，到政府层面的引导性采购，再到企业之间分工合作关系的建立，以及风险投资机构的进入，已形成了可自我持续发展的商业航天产业生态。
        </p>
        <p>
          相比美国，我国商业航天虽然起步比较晚，但发展势头迅猛。2014年国务院发布的《关于创新重点领域投融资机制鼓励社会投资的指导意见》、2016年国务院发布的《“十三五”国家战略性新兴产业发展规划》和《2016中国的航天》白皮书提出了“鼓励引导民间资本参与航天科研生产，大力发展商业航天和卫星商业化应用，完善政府购买航天产品与服务机制”等内容。持续一系列利好商业航天的政策出台，进一步推动了商业航天的快速发展。
        </p>
        <p>
          随着社会资本的进入以及各项政策的出台，国内已成立了近百家商业航天公司。然而，与商业航天快速发展的现状不相适应，我国在商业航天立法、行业政策、资质准入、资源共享、市场培育及产业发展等方面仍不够完善。因此，我建议：
        </p>
        <p>
          一、加快推动航天立法，确保民营企业长期稳定、合理有效利用空间资源的权利
        </p>
        <p>
          目前，世界上已有29个国家颁布了航天领域的国内法律或法规，美国建立了最为完善的商业航天法体系，明确规定了政府和军方、民用航天和军事航天的权责边界，鼓励通过商业服务获得航天自主发展能力。
        </p>
        <p>
          我国是航天大国中唯一没有航天法的国家，商业航天公司缺乏明确的国家法律保障。亟需加快航天立法进程，建立商业航天市场准入退出、公平竞争、保险和赔偿、安全监管等机制，构建较为完善的商业航天法律体系。充分发挥“法治航天”的战略引领作用，确保不同企业主体长期合法享有同等市场地位，从国家法律层面护航商业航天健康发展。
        </p>
        <p>二、探索有利于商业航天发展的机制，出台落地政策及实施细则</p>
        <p>
          商业航天属于快速发展的新兴行业，门槛高、投资大、战略意义显著，比多数产业更容易受到政府监管和行业政策的影响。亟需破解商业航天发展的体制机制桎梏，引导商业航天市场规范有序发展。
        </p>
        <p>
          建议通过政策引导国企与民企合理分工，形成协同合作、竞争有序的产业格局。制定商业卫星、火箭等产品的进出口许可证制度、商业火箭发射许可、商业卫星频率许可等政策实施细则，探索建立航天人才双向流动、国有航天企业技术转让等机制，切实包容、鼓励商业航天新业态和商业新模式，进一步推动监管政策与制度的实施落地，清除民营企业深度参与商业航天的政策障碍。
        </p>
        <p>
          三、推进航天制造基础设施开放共享，构建适应商业化的量产智能制造生态系统
        </p>
        <p>
          航天装备水平是衡量国家高科技生产力高低的重要标志之一，商业火箭、卫星及卫星用户地面配套等的量产对产品成本、周期、性能等均提出了新需求，极大拓展了航天装备制造内涵和外延，传统航天制造模式和配套技术难以满足所需。
        </p>
        <p>
          建议由政府统筹，国企、民企多方聚力，布局商业航天产品智能制造，鼓励民企参与航天装备制造相关的国家重点项目，加速颠覆性航天技术创新与应用；制定商业航天装备产品量产及上下游企业的培育政策及实施细则，加大航天智能制造技术共享和转化力度，开放国家航天制造基础设施，颁布航天试验设施共享目录、有偿使用收费标准等。
        </p>
        <p>
          四、完善落实政府采购商业航天产品与服务机制，开放商业航天公司的行业准入，拓展商业服务与应用领域
        </p>
        <p>
          纵观美国商业航天发展历程，不难发现通过技术转让、给予合同，政府扶持商业航天公司、培育商业航天市场，一手推动了美国民营商业航天的发展。
        </p>
        <p>
          为加速发展我国商业航天产业，建议加大政府的扶持力度，授予我国商业航天公司一定比例的政府订单；简化商业火箭发射、航天测控、无线电频率等审批程序，引导鼓励民营企业战略性空间资源布局，承担轨道环境有序可控的应尽责任；进一步开放已有发射场，新增发射工位，满足高频次商业发射服务需求；降低或放开应用商业卫星开展服务的行业准入，拓展商业卫星在大众生活和政府管理的应用，服务国家“一带一路”、精准扶贫等国家战略。
        </p>
        <p className={style.subtitle}>关于进一步深化落实信息无障碍建设的建议</p>
        <p>
          改革开放四十年，我国的社会主义建设取得了重大胜利，人民生活水平显著提高。现阶段，带动弱势群体奔小康，已经成为中央关注人民幸福感提升的重点内容。习近平总书记特别指出：“中国有几千万残疾人，2020年全面建成小康社会，残疾人一个也不能少。为残疾人事业做更多事情，也是全面建成小康社会的一个重要方面。”
        </p>
        <p>
          《中国残疾人事业研究报告(2018)》蓝皮书和中国残联等机构数据显示，我国现在残障群体人数已经超过8500万，视障群体人数已达1700万，听障人群超过2000万。随着老龄化趋势加强，加强信息无障碍建设将直接惠及约两亿人。
        </p>
        <p>
          在实践中，我们注意到广大视障、听障和老龄群体对于移动互联网和人工智能物联网的需求非常强烈。这类技术应用对于健全人是改善生活，对于他们则是改变命运，如移动终端和应用程序在获取信息、便捷出行、通讯交流等方面对残障人士有巨大帮助。语音交互、物联网、智能硬件等创新科技又为他们改善生活提供了新的解决路径。做好信息无障碍工作是科技工作者职责，也是让每个人平等享受科技带来美好生活权利的微观体现。
        </p>
        <p>
          和世界先进国家相比，我国的信息无障碍水平尚有一定差距，影响了残障同胞等弱势群体奔小康的进程。突出表现在相关法律法规已经颁布，但落地力度不足、保障细节尚未出台。社会各方面意识还比较薄弱，监督与激励等配套措施也并不完善，信息无障碍相关产业链（硬件、软件、内容和服务）还不够健全。
        </p>
        <p>
          我们发现信息无障碍不仅是技术研发问题，更是一个需要政府、企业、配套产业链、社会组织等多方面统筹协调、紧密协作的系统工程，还涉及到全社会相关意识的提升。进一步深化落实信息无障碍要求，加强人工智能物联网信息无障碍建设已经迫在眉睫，是迅速大幅提升弱势群体的幸福感，树立全球科技惠民标杆的重要机遇。因此，我建议：
        </p>

        <p>一、细化相关法律法规关于信息无障碍的要求，出台落地时间表</p>
        <p>
          本世纪初，联合国就已经将信息无障碍列入行动纲领。万维网联盟（W3C）、国际电信联盟（ITU）等多家国际组织也出台了相应的技术标准，我国在实践中也参考借鉴了国际先进经验，特别是W3CWAI标准。国务院、工信部、中残联也先后出台多部法规推动信息无障碍建设，随着技术的高速发展，现有的法规需要进一步完善。
        </p>
        <p>
          建议在《无障碍环境建设条例》明确增加并细化信息无障碍内容，特别是针对移动互联网和人工智能物联网能够提出细化的操作要求，力争残障群体和老年人接入信息服务时，能够买得到、买得起、用得了、用得体面。
        </p>
        <p>
          同时，也建议相关部委能够出台时间表，要求政府机构、社会组织在信息服务上分阶段实现无障碍。建议设立中国信息无障碍贡献奖，表彰做出巨大贡献的单位和个人。
        </p>
        <p>
          二、政府和公共部门提供服务满足无障碍基础要求，将信息无障碍列入相关采购标准
        </p>
        <p>
          在信息无障碍的建设中，政府和公共部门是牵头人，对全社会有着风向标的示范效应。特别是在智慧城市建设上，不能因技术问题而让残障群体掉队。
        </p>
        <p>
          建议在政府和公共部门（如医院、地铁、公交、银行）提供的公共信息服务上，率先实现信息无障碍要求，如政府服务的应用程序能够达到手机无障碍要求；规范公交车的入站语音报站流程，加快公交车的服务数据化和辅助残障人士便利出行的应用的建设，如可考虑基于地理定位向残障人士的手机推送语音出行信息提示；银行的自助业务办理系统（自动柜员机、网站和应用程序）能够达到信息无障碍的基础要求等。
        </p>
        <p>
          建议政府部门在采购移动终端、智能硬件以及软件服务（网站、应用程序）时，能够将信息无障碍要求列入采购标准。
        </p>
        <p>
          三、制订人工智能物联网信息无障碍标准，推动联网智能硬件纳入辅具采购名录
        </p>
        <p>
          人工智能物联网（AIoT）已经成为信息产业的新风口，我国已经建成全球最大的消费级物联网平台，接入智能硬件数量处于领先地位。基于人工智能物联网与智能硬件的联动，可以大大降低残障群体和老年人等群体接入信息社会的成本。比如视障及肢障群体通过语音交互的方式，借助智能音箱便利地完成启动扫地机器人、开启空调、获取文图视听资讯等操作。
        </p>
        <p>
          建议相关部委制订人工智能物联网的信息无障碍标准，对产品设计、硬软件结合进行通用性规范，达到残障群体与健全人可以同样使用产品的目标。
        </p>
        <p>
          同时，建议各地政府对残障人士购买智能音箱、智能灯具等能明确帮助残障人士提升生活水平的智能硬件给予相应的购买补贴。此外，将符合无障碍标准、能切实帮助残障人士的智能硬件纳入残疾人辅助器具名录，有助于让残障同胞更方便地享受万物互联带来的美好生活。
        </p>
        <p>
          建议统一养老数据标准和交换标准，让智能硬件厂商可以基于标准开发产品，从而推进全国养老大数据联网建设。
        </p>
        <p>四、鼓励互联网企业践行企业社会责任，积极投入信息无障碍建设</p>
        <p>
          我国相关企业在信息无障碍建设方面已初具基础，而且人工智能物联网又提供了全新的发展机遇，但目前全产业链的建设并不完备。
        </p>
        <p>
          产业链的完备首先体现在产品、服务丰富度方面。信息无障碍对产品、服务有着非常丰富的多样化需求，如视障人士希望视觉信息可听可触，听障人士希望声音信息可视化，需要多方参与、满足多样的需求。
        </p>
        <p>
          更重要的是，在信息无障碍的建设上，企业是一线核心，主要涉及移动终端、应用程序、智能硬件、内容生产等多个维度，涵盖了信息产业全链条的诸多环节，环环相扣，缺少任何部分都会造成无障碍体验缺失。
        </p>
        <p>
          建议政府鼓励相关企业积极投入信息无障碍产业链，补齐服务缺失短板；在企业、机构自身产业链角色中，投入更多一线研发和运营资源，提高无障碍的开发优先级，特别是直接对接残障群体，倾听他们的需求；鼓励大企业分享开发经验，与政府及社会组织积极协作。同时，也建议政府对于成绩显著、有带头作用的企业能够给予专项资金、税费减免等扶持政策。
        </p>
        <p>
          五、在高等院校相应院系增加信息无障碍开发课程，鼓励全社会提供综合配套服务举措
        </p>
        <p>
          信息无障碍是一个需要全社会参与的系统工程，教育机构是重要参与者，特别是在信息无障碍服务技能和意识的培养，以及产学研结合方向上有着不可替代的作用。
        </p>
        <p>
          建议在高等院校和科研机构的计算机、软件工程专业的开发课程中增加无障碍开发课时，在工业设计系增加通用设计课程；鼓励教育机构将信息无障碍列入通识教育；鼓励全社会提升信息无障碍意识，并提供综合配套服务举措。
        </p>
        <p>
          建议地方政府设置配套资金，用于给残障人士学习使用移动终端、智能硬件的学习培训。鼓励相关机构和企业积极参与国际相关标准制订，特别是在移动互联网、人工智能物联网等新领域输出中国经验。
        </p>
      </div>
    </div>
  );
};

export default Suggestion;
