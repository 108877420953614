/* eslint-disable no-shadow-restricted-names */
import React from 'react';
import style from '../index.module.scss';

const pic1 = require('../../../assets/img/about/hong.png');
const pic2 = require('../../../assets/img/about/hxin.png');
const pic3 = require('../../../assets/img/about/hyue.png');
const pic4 = require('../../../assets/img/about/jiayan.png');
const pic5 = require('../../../assets/img/about/xkang.png');
const pic6 = require('../../../assets/img/about/huoying.png');
const pic7 = require('../../../assets/img/about/tree.png');
const pic8 = require('../../../assets/img/about/poster.png');
const pic9 = require('../../../assets/img/about/logo.png');

const Infinity = () => {
  return (
    <div className={style.infinityWrapper}>
      <div className={style.title}>从100到∞ | 系列采访第5期：“我能”</div>
      <div className={style.time}>2020-5-17</div>
      <div className={style.content}>
        <p>和朋友在手机上聊个天需要几个步骤？ </p>
        <p>解锁-打开微信-找到对话框-开始聊。 </p>
        <p>现在请你闭上眼，把以上动作重复一遍。 </p>
        <p>成功了吗？ </p>
        <p>
          还是这个流程，请你打开手机设置中的无障碍Talkback功能（Android）或旁白功能（iOS），简单学习一下使用方法，然后闭上眼，再试一次。
        </p>
        <p>感觉如何？ </p>
        <p>刚刚你体验的这个功能，是视障用户使用智能手机必不可少的工具。 </p>
        <p>
          在中国，包括1800万视障人士、2700万听障人士在内的残疾人数量是8500万（2017年数据），这意味着你身边大约每17个人中就有一位无障碍功能的用户。
        </p>
        <p>
          他们和你一样，努力工作、认真生活，有的热爱数码，有的喜欢追星，没事儿刷刷剧，日常段子手。
        </p>
        <p>
          虽然多了一些局限，但手机和各种智能设备，正成为他们的眼睛、耳朵、手和足。
        </p>
        <p>
          今天是全国助残日（5月的第三个星期日），我们把他们和小米的故事说给你听。
        </p>
        <p className={style.subtitle}>01 一封来信</p>
        <p>
          2015年11月18日，小米社区有位叫“清冷的刀锋”的用户发布了一个帖子。这个帖子有点特别——特别长，五大部分，涵盖前言、详细反馈与建议、总结、结语和备注，图文结合，一共有6000多字。它的标题是“MIUI7读屏软件无障碍反馈报告”，作者是一群视力障碍的小米手机用户。
        </p>
        <p>刀锋是这篇报告的发起人。</p>
        <p>
          早在2013年，他就在小米社区发过无障碍功能建议帖，帖子发出即有小米同学联系了他，bug很快被修复，刀锋既欣喜也有点意外，“发帖的时候确实是很期待有回复，但也没抱太大的期望，毕竟这个需求听起来很小众。”
        </p>
        <p>
          彼时，智能手机已经开始普及，但对视障群体来说，大部分的智能机并不能用，这倒不是因为技术实现上有什么困难——当年的iOS和Google原生安卓系统都已经配备了辅助视障用户使用的屏幕朗读功能，只是国内厂商在定制安卓UI时常常直接将这一功能部分或者完全阉割。MIUI是为数不多完整保留了这一功能的系统，但说实话，直到刀锋发了帖子，大家才意识到原来真的有视障用户在使用我们的手机，bug修复之后，这个功能慢慢真正可用起来。
        </p>
        <p>
          {' '}
          这次反馈的成功试水给刀锋和“盲人安卓爱好者”社区的小米用户们开辟了一条新的路。15年底，刀锋要发建议帖的想法在数码爱好者们中得到了热烈响应，近十人的小组历经半个多月的测试、验证，整理出这份完整而详实的报告。
        </p>
        <p>
          报告发出之后的反响出乎意料地好，回复之中，还有小米联合创始人洪锋（洪帮主）的留言。
        </p>
        <img src={pic1} alt="图片：洪帮主的留言" />
        <p>小米无障碍，起航了。</p>
        <p>
          这个帖子作为珍贵的历史资料，和它所引发的连锁反应一起成为了小米无障碍代代相传的故事。
        </p>
        <p>
          海鑫的第一次认知刷新就是因为这个故事。海鑫是现在小米无障碍小组的产品经理，刚刚接手这份工作时他对无障碍并没有概念，这个帖子的故事是他的无障碍“第一课”。
        </p>
        <img src={pic2} alt="图片：海鑫" />
        <p className={style.subtitle}>02 想象 v.s. 实际</p>
        <p>首先被打破的刻板印象是残障群体本身的形象。</p>
        <p>
          他们是什么样的人？沉默而敏感吗？是否随时需要帮助？视障人是不是特别依赖收音机？
        </p>
        <p>海鑫记得第一次和视障用户沟通，就被对方飞快的打字速度震惊。</p>
        <p>
          越接触，就越了解，也就越脱离想象和误解。这群用户从符号化的存在，逐渐还原成一个个鲜活的个体——有的活泼顽皮，有的思维缜密，有人喜欢抢红包，有人热爱买买买...总之，除了视力上多一些局限，他们和其他人没什么不同。
        </p>
        <p>从想象到实际的第二个转折点，是了解这个群体的真正需求。</p>
        <p>
          运营同学海月和海鑫差不多同一时间加入无障碍小组。现在的她日常活跃在一个近500人的用户QQ群中，对用户的反馈能迅速理解和反应。
        </p>
        <img src={pic3} alt="图片：海月" />
        <p>
          入职之初，海月也经历过“有点懵”的时期：视障朋友为什么会对手机壁纸有需求？他们为什么也要用拍照和相册的功能？他们看电视是做什么呢？
        </p>
        <p>
          当放下这些固执的印象，把“我们”和“他们”的界线抹掉，海月发现这些看似难以理解的需求其实是多么自然而然。
        </p>
        <p>
          一位用户会把去世妻子的照片设置成手机壁纸；一位妈妈想要记录女儿成长的每个瞬间；有人想要和朋友一起看电影；有人想要和家人一起看春晚；有人想要知道新鲜事...
        </p>
        <p>
          和世界保持连接，和家人朋友共度美好时光——这些需要并不因为眼明与否而受影响，也不应该因为视力障碍而被剥夺。
        </p>
        <p>
          海月在一次演讲中说，“有时候我觉得与其把人分为明眼人和视障人，不如分为想或不想和手机和互联网生活的人。技术对想要使用手机和互联网的人应该一视同仁。”
        </p>
        <p>
          在18年底的一次视障用户座谈会上，海鑫受到了同样的观念重塑。想要通过座谈会挖掘用户需求的他并没有收到想象中五花八门的特殊提案，而是一个振聋发聩的回答：“我们没什么独特的需求，我们的需求就和你们的一样。”
        </p>
        <p>这个回答来自王老师——资深用户王慧。</p>
        <p>
          王慧一直是打破人们刻板印象的那个人。他是兰州大学历史上首位盲人学士，也是天津首位通过国家司法考试的视障人士——你以为视障人甚至非视障人都很难完成的事情，他都做到了。
        </p>
        <img
          src={pic4}
          alt="图片：小米家宴，这熟悉的背影，是谁在加王老师微信"
        />

        <p>
          十年来，他一直在坚持帮助视障用户使用智能手机和设备。熟稔各种数码产品的他看到了两种截然不同的设计思路。
        </p>
        <p>
          一种是为残障群体“定制”产品，比如做一个拥有特殊物理按键或者盲文点显器的盲人手机。
        </p>
        <p>
          另一种则更体现包容 (inclusion)
          的理念：如果人和人的需求本质上没什么区别，视障用户真正需要的只是一台对他们来说使用友好的普通手机。做到这一点其实不用费多大功夫，只需让手机本身的辅助功能适配各个应用就可以。
        </p>
        <p className={style.subtitle}>03 该怎么做？</p>
        <p>
          在技术上，这并没什么困难，任何一位合格的开发者只需要一两个小时的学习就可以上手。很多时候，无障碍相关的开发也就是多几行代码的工作量。
        </p>
        <p>更挑战的是真正站在用户的角度去理解现有产品在使用上的问题。</p>
        <p>
          解决这一点，首先需要“沉浸式”体验，去感受使用者的感受——闭上眼睛，或者塞住耳朵，像文章开头那样，尝试去操作设备。一次短短的体验也能胜过千言万语。
        </p>
        <p>
          但这当然还不足够，复杂和丰富的使用场景中可能隐藏着各种问题。好在，通过获得直接的用户反馈发现这些问题，帮助改进产品，这个机制对小米同学来说并不陌生。
        </p>
        <p>
          在海月之前，和用户打交道最多的是前一任运营同学薛康（大家更习惯叫他的网名“胡杨”）。现在500人大群中最初的那批用户，就是薛康从小米社区的反馈帖中一个一个挖出来的。
        </p>
        <img src={pic5} alt="图片：薛康" />
        <p>
          刚接触无障碍的时候，薛康不仅恶补无障碍相关知识，还把小米社区几年来所有有关的帖子刷了一遍，甚至“潜伏”到各大视障用户社区里去了解盲人的使用需求和问题。
        </p>
        <p>
          “有一次他就被认出来了，我说你这个潜伏工作没做好啊。”几年过去，火影说起这件事儿来还是觉得又有趣又感动。
        </p>
        <p>火影也是一位资深用户。</p>
        <p>
          在反馈问题这件事儿上，火影是老资格——当年那篇长帖就有ta的一份力。火影说自己像在捉虫子，把有障碍的地方一点一点帮忙找出来。
        </p>
        <img
          src={pic6}
          alt="图片：火影，和火影的建议"
          style={{ width: '50%', marginLeft: '25%' }}
        />
        <p>
          捉虫子捉得多了，其他用户觉得火影能把问题说得更明白，常常都来找ta反馈，火影再把这些问题“翻译”给海月和海鑫。
        </p>
        <p>
          在MIUI早期的一次更新中，曾经有非视障用户对“盲人模式”提出过异议，担心这个功能占用内存，认为实际用处不大——自然又是不了解造成的误会。在视障伙伴的建议下，这之后的小米系统用“无障碍优化”替代了“盲人模式”的表述。让火影开心的是，社区里其他用户的理解也慢慢多了起来，有时候ta发言，还会有可爱的明眼人朋友帮着@工作人员。
        </p>
        <p>
          表述的转变也代表着理念的更新——无障碍设计并不是为某个群体需求进行新的定制，而是发现现有系统中的bug，这些bug就像我们日常遇到的各种bug一样，需要被修复和优化而已。
        </p>
        <p>
          事实上，无障碍设计常常不只惠及残障群体，这样的例子不胜枚举：路边的斜坡是为肢体障碍人群设计，却能在你拖着大箱子的时候帮上忙；键盘的前身打字机是为了让视障人写出工整漂亮的字；Email最初是帮助听障人进行书面沟通的工具，等等。
        </p>
        <p>真正的无障碍设计其实是普惠设计。</p>
        <p>
          录像模式的语音转字幕功能，不仅适合要制作影片的用户，也极大帮助了听障用户；电话自动回复功能，不仅帮你接住开会时的电话，也可以成为听障外卖小哥送餐时的神器；扫地机器人，既是懒人福音，更是肢体障碍和视障用户打扫时的得力助手...
        </p>
        <p>
          每一个普惠设计的出现，都是一次向平等的进步——这是生活体验的真正平等。
        </p>
        <p className={style.subtitle}>04 感动是互相的</p>
        <p>创造这种平等的过程也同时制造了一个良性循环。</p>
        <p>对无障碍用户来说，智能的介入让生活发生了翻天覆地的变化。</p>
        <p>
          在采访中，几乎每一个人都提到了“万能遥控”的例子。控制空调这么一件小事对视障用户来说一度是个大难题：每一次空调的开关、调温度、风速，都得靠记忆来判断操作的结果，而一旦有其他人使用了遥控，所有的信息就被打乱了。薛康和开发小伙伴一次偶然的尝试，给手机上的万能遥控适配了屏幕朗读标签，用户在每一次操作（调整温度/风速）之后，手机都会提示当前温度/风速的状态。这一个小小的动作，却像是给视障用户带来了一双眼睛。
        </p>
        <p>
          无数像这样，对别人来说从“好”到“更好”的体验提升，对无障碍用户来说，却是从“不能”到“能”的质的飞跃。王慧用”锦上添花“和”雪中送炭“来形容这之间的区别。
        </p>
        <p>在大群里，常常有人提到“永远相信美好的事情即将发生”。</p>
        <p>
          海鑫清楚地记得《梧桐树》（一部展现残障伙伴日常的纪录电影）观影后的交流活动中，影片主人公听障女孩李聪试用小米闻声demo的全神贯注，“她就那么一直盯着屏幕看，特别开心”，那一刻，从零孵化出这个产品的海鑫成就感爆棚。
        </p>
        <img src={pic7} alt="图片：《梧桐树》观影交流" />
        <p>
          把用户带到开发者面前，这样的活动触动了很多小米同学——当无障碍不再是列表上的需求和数字，而是一个个真实的人，更厚重的使命感油然而生。
        </p>
        <p>
          做无障碍这件事对薛康、海月、海鑫，还有来不及接受我们采访但一直奋斗在推动无障碍一线的朱汐（上文观影活动的发起人）这几个小伙伴来说，早已经超越了工作职责本身。一年多前调岗离开无障碍小组的薛康，现在依然每周跟“鑫月组合”碰上几回，常常在群里和用户老友们讨论。
        </p>
        <p>每一个来自使用者的点赞和感叹，都是比“考核”更有力量的激励。</p>
        <p>
          就像海月的一个签名“热爱可抵岁月漫长”，做无障碍并不容易，他们最大的感触却是“值得”。
        </p>
        <p>--</p>
        <p>
          七八年前，刀锋对未来生活有过一些畅想——科技带来极大的便利，回家之后有机器人帮你开门、开灯、开空调...
        </p>
        <p>美好的是，在今天，这些已经全都实现了。</p>
        <p>
          想象未及之处，也有持之以恒的耕耘。这些耕耘之中，是一个个微小而实在的进步，就像备受喜爱的“万能遥控”一样，每一个朴素的功能背后，却寓意着深刻的改变——它们让每一个人从我不能，到我能。
        </p>
        <p>
          这也是无障碍路上的每一个伙伴对未来的愿景：创造适合的环境，让所有残障伙伴，都能自如地生活、学习和工作，全面地融入到社会生活中。
        </p>
        <p>虽然这条路还挺长，但一群人一起，一定可以走得更远。</p>
        <p>让我们一起相信美好的事情正在并即将发生。</p>
        <img
          src={pic8}
          alt="图片：小米无障碍进入2.0时代"
          style={{ width: '50%', marginLeft: '25%' }}
        />
        <p>
          小米愿无偿分享开发经验，和全球科技公司共同努力，推动无障碍的全面实现。
        </p>
        <p>
          如果你对无障碍设计感兴趣（无论你是否是小米同学），欢迎联系 杜海鑫
          (duhaixin@xiaomi.com) 或 王海月 (v-wanghaiyue@xiaomi.com){' '}
        </p>
        <p className={style.subtitle}>关于“从100到∞”</p>
        <img src={pic9} alt="" />
        <p>
          “从100到∞”是由互联网一部小米社区与企业文化组联合出品，以米粉群体为对象的采访活动，每月我们都将邀请出几位资深的米粉进行采访，从不同的主题与侧重点，展现米粉与小米不可分割的紧密联系，以及小米公司独特的米粉文化。
        </p>
        <p>
          “从100到∞”这个名字与米粉是紧紧绑在一起的，其中的“100”指的便是小米诞生初期的100个梦想赞助商，而“∞”则是在小米近10年的成长当中，不断壮大的米粉们，米粉文化自小米诞生的那一刻起就牢牢地刻在了小米的印记当中，这是小米偾张着的血脉，也是小米跳动着的心脏。这些充满梦想与热情的面孔，陪伴、见证了小米一路走来的风雨历程，而在未来，也必定会有更多的米粉加入到小米大家庭，一起发挥无穷大的力量，陪小米走过一个又一个灿烂的十年。
        </p>
      </div>
    </div>
  );
};

export default Infinity;
