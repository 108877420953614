import React from 'react';
import style from '../index.module.scss';

const pic = require('../../../assets/img/about/pic2.png');

const Special = () => {
  return (
    <div className={style.specialWrapper}>
      <div className={style.title}>揭秘：5月第3个周日除了520还是啥日子？</div>
      <div className={style.time}>2018-05-20</div>

      <div className={style.content}>
        <p>
          今天，5月的第三个周日，
          <br />
          只是520那么简单吗？
          <br />
          特别的爱给特别的520.
          <br />
          看了这个视频更加确信，
          <br />
          真正爱你的人，是不用确认眼神的。
        </p>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video controls="controls" poster={pic} preload="metadata">
          <source
            src="https://cdn.cnbj1.fds.api.mi-img.com/h5-base/assets/special.mp4"
            type="video/mp4"
          />
        </video>
        <p>
          蔡聪：每个人都有享受让自己变得更美、更自信的权利。整个技术的发展，就是在不断消除我们生活中可能遇到的障碍，让彼此之间的差异不再是一个问题。
        </p>
      </div>
    </div>
  );
};

export default Special;
