import React from 'react';
import MainItem from './mainItem';
import SubItem from './subItem';
import style from './index.module.scss';

const data = require('../data.json');

const Content = () => {
  return (
    <>
      <div className={style.wrapper}>
        <MainItem data={data.mainContent} />
        <SubItem data={data.subContent} />
      </div>
    </>
  );
};

export default Content;
