/* eslint-disable no-script-url */
import React from 'react';
import style from './footer.module.scss';
import icon from '../../assets/img/police.png';
// import wechat from '../../assets/img/homepage/wechat.png';

const Footer = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.link}>
        <div>
          <a
            href="https://weibo.com/miui"
            target="_blank"
            rel="noopener noreferrer"
          >
            新浪微博
          </a>
        </div>
        <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div>
        <div className={style.wechat}>
          <div>官方微信</div>
          <div className={style.wechatImg} />
        </div>
        <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div>
        <div>
          <a
            href="http://tieba.baidu.com/f?kw=miui"
            target="_blank"
            rel="noopener noreferrer"
          >
            官方贴吧
          </a>
        </div>
        <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div>
        <div>
          <a
            href="http://www.miui.com/fans.php"
            target="_blank"
            rel="noopener noreferrer"
          >
            全球粉丝站
          </a>
        </div>
        <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div>
        <div>
          <a
            href="http://www.miui.com/unlock/index.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            解锁
          </a>
        </div>
        <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div>
        <div>
          <a
            href="http://www.miui.com/res/doc/privacy/cn.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            隐私政策
          </a>
        </div>
        <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div>
        <div>
          <a href="http://www.miui.com/zt/presetsApplication/index.html">
            预置应用查询
          </a>
        </div>
        <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div>
        <div>简体中文</div>
      </div>
      <p
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        © mi.com 京ICP备10046444号 |{' '}
        <img src={icon} alt="" style={{ padding: '0 4px' }} />
        京公网安备11010802020134号 | 京ICP证110507号
      </p>
    </div>
  );
};

export default Footer;
