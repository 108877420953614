import React from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import Header from '../../components/header/header';
import Content from './content/index';
import Detail from '../detail/index';
import Footer from '../../components/footer/footer';
import ScrollToTop from '../../components/scrollToTop/scrollToTop';
import style from './about.module.scss';

const About = () => {
  // hashHistory.listen((location) => {
  //   setTimeout(() => {
  //     if (location.action === 'POP') return;
  //     window.scrollTo(0, 0);
  //     console.log('Back to top', location);
  //   });
  // });

  return (
    <>
      <div className={style.aboutHead}>
        <Header color="light" />
      </div>
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path="/about">
              <Content />
            </Route>
            <Route path="/about/:id" component={Detail} />
          </Switch>
        </ScrollToTop>
      </Router>
      <Footer />
    </>
  );
};

export default About;
