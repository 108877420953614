import React from 'react';
import style from './lunbo.module.scss';

const Lunbo = ({ children, direction }) => {
  let classWrapper = style.wrapper;
  if (direction === 'right') classWrapper += ` ${style.reverse}`;
  return (
    <div className={classWrapper}>
      {children}
      {children}
      {children}
    </div>
  );
};

export default Lunbo;
