/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import HomePage from './pages/homepage/homepage';
import About from './pages/about/about';

const pic1 = require('./assets/img/about/pic1.png');
const pic2 = require('./assets/img/about/pic2.png');
const pic3 = require('./assets/img/about/pic3.png');
const pic4 = require('./assets/img/about/pic4.png');
const pic5 = require('./assets/img/about/pic5.png');

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route path="/about">
            <About />
          </Route>
        </Switch>
      </div>
      <img src={pic1} style={{ display: 'none' }} />
      <img src={pic2} style={{ display: 'none' }} />
      <img src={pic3} style={{ display: 'none' }} />
      <img src={pic4} style={{ display: 'none' }} />
      <img src={pic5} style={{ display: 'none' }} />
    </Router>
  );
}

export default App;
