import React from 'react';
import style from '../index.module.scss';

const pic1 = require('../../../assets/img/about/union.png');
const pic2 = require('../../../assets/img/about/unionPoster.png');

const Union = () => {
  return (
    <div className={style.unionWrapper}>
      <div className={style.title}>今天，小米带着这支短片去了趟联合国</div>
      <div className={style.time}>2018-12-03</div>
      <div className={style.content}>
        <p>
          今天是第27个联合国国际残障者日。
          <br />
          小米集团互联网一部总经理李伟星受邀来到联合国举办的「国际残障者日-中国残障政策对话」活动，代表中国企业向在场的联合国驻华机构、学者、NGO和政府官员代表，介绍小米信息无障碍的发展历程。
        </p>
        <img
          src={pic1}
          alt="图片 联合国教科文组织驻华代表处主任欧敏行博士对小米的开发模式高度评价"
        />
        <div className={style.subtext}>
          联合国教科文组织驻华代表处主任欧敏行博士对小米的开发模式高度评价
        </div>
        <p>
          李伟星先发布了一支短片。今年9月，小米邀请8名管培生，在8名视障引导师的指导下，体验了一天「失明」生活。短片记录了管培生的特别体验与思考，这也是小米在全面提升员工无障碍意识上的新尝试。
        </p>
        <video controls="controls" poster={pic2} preload="metadata">
          <source
            src="https://cdn.cnbj1.fds.api.mi-img.com/h5-base/assets/union.mp4"
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  );
};

export default Union;
