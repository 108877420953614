/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
import React, { useState, useRef } from 'react';
import useObsever from '../../../util/index';
import style from './index.module.scss';

const ContentItem = (props) => {
  const { id, title, introduction, src, alt, specialStyle } = props;
  const [arrived, setArrived] = useState(false);
  const ref = useRef(null);
  useObsever(ref, setArrived);
  const textLeft = !!(id % 2);

  return (
    <div
      className={`${style.box} ${
        textLeft ? style.text_right : style.text_left
      }`}
      ref={ref}
    >
      <img
        className={`${style.image} ${specialStyle && style[specialStyle]}`}
        src={src}
        alt={alt}
      />
      <div className={`${style.text} ${arrived && style.enter}`}>
        <div className={style.title}>{title}</div>
        <p className={style.introduction}>{introduction}</p>
      </div>
    </div>
  );
};

export default ContentItem;
