/* eslint-disable prefer-template */
import React, { useRef, useState } from 'react';
import useObserver from '../../../util/index';
import style from './index.module.scss';
import ContentItem from './contentItem';
import allData from '../data';

// eslint-disable-next-line camelcase
import listenLeft from '../../../assets/img/homepage/listen_left.png';
import listenRight from '../../../assets/img/homepage/listen_right.png';

const data = allData.middle.xmListen;

const XmListen = () => {
  const [arrived, setArrived] = useState(false);
  const ref = useRef(null);
  useObserver(ref, setArrived);
  return (
    <div className={style.container}>
      <div className={style.wrapper} ref={ref}>
        <div className={style.top + ' ' + (arrived && style.enter)}>
          <p className={style.title}>强大的小米闻声</p>
          <div className={style.introduction}>
            与讯飞听见合作，倾力打造出的听力辅助工具，适用于听障用户的多种场景。提供灵活切换的全局与小窗模式、随心切换的内录与外录能力、精准的音字互转服务，讯飞听见引擎支持实时语音转文字，中文普通话识别准确率高达98%。{' '}
          </div>
          <div className={style.image}>
            {/*  eslint-disable-next-line camelcase */}
            <img src={listenLeft} alt="小米闻声主页面" />
            {/*  eslint-disable-next-line camelcase */}
            <img src={listenRight} alt="小米闻声小窗页面" />
          </div>
          <div className={style.mask} />
        </div>
        <div className={style.content}>
          {data.map((item) => {
            return (
              <ContentItem
                key={item.id}
                id={item.id}
                title={item.title}
                introduction={item.introduction}
                src={item.src}
                alt={item.alt}
                specialStyle={item.specialStyle}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default XmListen;
