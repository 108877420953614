import { useEffect } from 'react';

export default function useObserver(ref, callback) {
  // 交叉观察器:可自动"观察"元素是否可见（目标元素与视口产生一个交叉区）
  // callback触发条件:目标元素刚刚进入视口（开始可见）;完全离开视口（开始不可见）
  // entrie数组的每个成员都是一个IntersectionObserverEntry对象。
  // 同时有几个被观察的对象的可见性发生变化，entries数组就会有几个成员。
  useEffect(() => {
    const io = new IntersectionObserver(
      (entries) => {
        if (entries[0].intersectionRatio <= 0) return;
        callback(true);
      },
      { thresold: [0] }
    );
    io.observe(ref.current);
  });
}

// export default function useObserver(ref, callBack, threshold = 0) {
//   // 注意，如果想将threshold改成数组，请一定一定要注意缓存的问题，不然会无限触发绑定
//   useEffect(() => {
//     let intersectionObserver = new IntersectionObserver(
//       (entries) => {
//         const entry = entries[0];
//         if (entry.intersectionRatio <= threshold) return;
//         callBack(true);
//         // 解除绑定
//         intersectionObserver.unobserve(entry.target);
//         intersectionObserver = null;
//       },
//       { threshold: [threshold] }
//     );
//     intersectionObserver.observe(ref.current);
//   }, [ref, callBack, threshold]);
// }
