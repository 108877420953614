/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-closing-tag-location */

import React from 'react';
import style from '../index.module.scss';

const pic1 = require('../../../assets/img/about/pic6.png');
const pic2 = require('../../../assets/img/about/xmListen.png');
const pic3 = require('../../../assets/img/about/camera.png');
const pic4 = require('../../../assets/img/about/screen.png');
const pic5 = require('../../../assets/img/about/business.png');
const pic6 = require('../../../assets/img/about/xiaoaiOpen.png');
const pic7 = require('../../../assets/img/about/nxia.png');
const pic8 = require('../../../assets/img/about/biaozhu.png');
const pic9 = require('../../../assets/img/about/waiter.png');

const HelpTheDisabled = () => {
  return (
    <div className={style.helpWrapper}>
      <div className={style.title}>有爱，无障碍</div>
      <div className={style.time}>2020-5-27</div>
      <div className={style.content}>
        <p>今天是5月的第三个星期日，第30个全国助残日。</p>
        <p>
          2018年的这一天，小米发布了
          <a href="https://accessibility.mi.com/#/about/2">
            《揭秘：5月第3个周日除了520还是啥日子？》
          </a>
          这是我们第一次对外谈论小米由来已久的无障碍功能。也许很多人也是那时候才知道，原来视障人士也能用小米手机。后来我们慢慢地了解到，小米的智能家居也颇受残障用户的好评和倚赖。
        </p>
        <p>
          小米一直都认为障碍是每个人生活中都会遇到的状态，我们做无障碍不仅仅是满足残障用户的需求，也在满足每个人在生活中随时会遇到的不同状况。
        </p>
        <p>
          小米的无障碍优化之路，缘起于一封2013年发在MIUI论坛的盲人用户来信，如今无障碍已成为小米系统完整性的必备组成。用户参与的开发模式成为一种传统，从产品、研发、测试到运营的全链条保障，也让MIUI成为国产安卓无障碍的领先者。刚刚发布的MIUI12，还正式发布了针对听障者的功能优化。
        </p>
        <p>
          但，这不是小米无障碍目前进展的全部。经过两年的宣推和内部建设，小米的无障碍已经全面升级，各业务部门已陆续展开业务调研、启动相关优化，并且建立了收集用户反馈和需求调研的QQ群。（拉到最后有群号）小爱同学开放了语音能力，希望有更多的开发者可以基于人工智能的能力一起创造出更多有价值的功能。
        </p>
        <p>
          因为我们相信，我们想要的世界，需要我们一起来创造：没有障碍，包容差别，接纳不同的人，满足丰富的需求。也许这一切暂时还不完美，但值得我们一起努力。
        </p>
        <p>
          先来了解一下全面升级的内容吧！（为便于视障用户了解图片信息，本条均配有文字解说）
        </p>
        <img
          src={pic1}
          alt="【小标题】5.17全国助残日
                    【大标题】有爱，无障碍
                    【副标题】真正让每个人都能享受科技带来的美好生活
                    "
          style={{ marginLeft: '25%' }}
        />
        <p className={style.subtitle}>
          支持升级 从视觉支持，到听力、表达等多元支持
        </p>
        <img src={pic2} alt="图片:小米闻声产品" style={{ marginLeft: '25%' }} />
        <p>
          小米闻声与讯飞听见一起打造听力辅助工具，适用于听障用户多种场景，支持实时语音文字互转，普通话识别准确率高达98%。
        </p>
        <img
          src={pic3}
          alt="图片:相机语音字幕产品图"
          style={{ marginLeft: '25%' }}
        />
        <p>
          录制有声视频，同步添加文字字幕，支持修改，一键生成内嵌字幕视频，让你的视频无声时也能传达更多信息。
        </p>
        <p className={style.subtitle}>
          业务升级 从手机系统，到多产品启动无障碍优化
        </p>
        <img
          src={pic4}
          alt="图片:小米手机屏幕、小米电视屏幕"
          style={{ marginLeft: '25%' }}
        />
        <p>
          米家、小米商城、小爱同学APP优化进行中；小米电视、小米盒子无障碍需求调研已启动；小爱同学无障碍模式下模拟点击功能完善中；敬请期待。
        </p>
        <p className={style.subtitle}>
          生态升级 从安卓体系到人工智能能力对外开放
        </p>
        <p>小米自研AI能力矩阵已赋能于内部多条业务线。</p>
        <img src={pic5} style={{ marginLeft: '25%' }} alt="" />
        <p>我们率先通过小爱开放平台，将语音能力开放给广大开发者。</p>
        <img
          src={pic6}
          alt="图片:小米小爱开放平台标识及二维码"
          style={{ marginLeft: '25%' }}
        />
        <p>
          希望有更多人加入为特殊需求用户开发设计产品的行列，未来我们将持续输出更多AI能力，应用于无障碍的创新。
        </p>
        <p className={style.subtitle}>
          责任升级 从做好产品，到创造更包容的社会环境
        </p>
        <p>
          向残障者自组织捐赠手机，让视障伙伴低成本学用智能手机，跨越视力界限，进入智能时代
        </p>
        <img
          src={pic7}
          alt="图片:跨视界手机助盲宁夏站现场照片"
          style={{ marginLeft: '25%' }}
        />
        <p>
          开放小爱同学标注员岗位，让视力障碍者和肢体障碍者，得到平等、有尊严的多元就业机会。
        </p>
        <img
          src={pic8}
          alt="图片:小爱视障标注员工作现场照片"
          style={{ marginLeft: '25%' }}
        />
        <p>
          向拥有35%残障员工的上海善淘慈善商店捐赠小米产品，支持社会企业的创新与发展。
        </p>
        <img
          src={pic9}
          alt="图片:听障服务员在善淘商店工作照片"
          style={{ marginLeft: '25%' }}
        />
        <p>
          {' '}
          随着科技的飞速发展，无障碍意识的普遍缺失带来的信息鸿沟，也在飞速加大。
        </p>
        <p>
          我们正在努力追赶，试图先让小米内部的产品达到通用、可用、尽量好用的程度。
        </p>

        <p>
          我们需要更多的障碍用户来进行反馈，提出要求，共同进化小米的无障碍体系。
        </p>
        <p>因此，请将这条信息转发给你身边的障碍伙伴，加入无障碍反馈群吧！</p>
        <p>
          <strong>视障用户反馈群</strong>
          <br />
          小米系统用户群：637585114
          <br />
          小米电视用户群：777585231
        </p>
        <p>
          <strong>听障用户反馈群</strong>
          <br />
          小米系统用户群：851817649
        </p>
      </div>
    </div>
  );
};

export default HelpTheDisabled;
