/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useRef } from 'react';
import daDian from '../../../util/daDian';
import useObserver from '../../../util/index';
import Lunbo from '../../../components/lunbo/lunbo';
import Footer from '../../../components/footer/footer';
import style from './index.module.scss';
import lunbo1 from '../../../assets/img/homepage/lunbo1.png';
import lunbo2 from '../../../assets/img/homepage/lunbo2.png';
import lunbo3 from '../../../assets/img/homepage/lunbo3.png';
import lunbo4 from '../../../assets/img/homepage/lunbo4.png';
import lunbo5 from '../../../assets/img/homepage/lunbo5.png';
import lunbo6 from '../../../assets/img/homepage/lunbo6.png';
import lunbo7 from '../../../assets/img/homepage/lunbo7.png';
import lunbo8 from '../../../assets/img/homepage/lunbo8.png';
import lunbo9 from '../../../assets/img/homepage/lunbo9.png';
import lunbo10 from '../../../assets/img/homepage/lunbo10.png';

const ConnectAndControl = () => {
  const [arrived, setArrived] = useState(false);
  const ref = useRef(null);
  useObserver(ref, setArrived);
  return (
    <div className={style.wrapper}>
      <div className={style.top} ref={ref}>
        <div className={style.largeTitle}>
          30<span>+</span>
        </div>
        <div className={`${style.text} ${arrived && style.enter}`}>
          <p className={style.title}>互联互通的设备控制</p>
          <div className={style.introduction}>
            多种智能设备内置小爱同学，同时可以通过米家操作多种智能设备，实现语音控制内置小爱同学的终端设备以及米家控制互联互通的智能终端。
          </div>
        </div>
      </div>
      {/* 产品展示轮播图 */}
      <div className={style.lunbo}>
        <Lunbo direction="left">
          <div className={style.imgWrapper}>
            <img src={lunbo1} alt="" />
          </div>
          <div className={style.imgWrapper}>
            <img src={lunbo2} alt="" />
          </div>
          <div className={style.imgWrapper}>
            <img src={lunbo3} alt="" />
          </div>
          <div className={style.imgWrapper}>
            <img src={lunbo4} alt="" />
          </div>
          <div className={style.imgWrapper}>
            <img src={lunbo5} alt="" />
          </div>
        </Lunbo>
        <Lunbo direction="right">
          <div className={style.imgWrapper}>
            <img src={lunbo6} alt="" />
          </div>
          <div className={style.imgWrapper}>
            <img src={lunbo7} alt="" />
          </div>
          <div className={style.imgWrapper}>
            <img src={lunbo8} alt="" />
          </div>
          <div className={style.imgWrapper}>
            <img src={lunbo9} alt="" />
          </div>
          <div className={style.imgWrapper}>
            <img src={lunbo10} alt="" />
          </div>
        </Lunbo>
      </div>
      <div className={style.xiaoai}>
        <div className={style.text}>
          <p className={style.title}>全场景的小爱同学</p>
          <div className={style.introduction}>
            小爱同学，通过其精准的声学模型，支持上百种常用技能，让打电话、发短信、查天气、定闹钟、导航等需要多步骤操作的事情，简化成为“一句话的事情”。
          </div>
        </div>
        <div className={style.videoWrapper}>
          <video preload="false" controls onPlay={daDian.videoXiao}>
            <source
              src="https://cdn.cnbj1.fds.api.mi-img.com/h5-base/assets/homepage.mp4"
              type="video/mp4"
            />
            抱歉，您的浏览器不支持内嵌视频，不过你可以通过
            <a href="videofile.ogg">下载</a>观看。
          </video>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ConnectAndControl;
