import React from 'react';
import style from '../index.module.scss';

const pic = require('../../../assets/img/about/summer.png');

const Summer = () => {
  return (
    <div className={style.summerWrapper}>
      <div className={style.title}>16位大一新生的特别“军训”夏令营</div>
      <div className={style.time}>2018-09-08</div>

      <div className={style.content}>
        <p>
          在刚刚过去的暑假，有16位准大学生，提前进行了为期一周的特别“军训”。和你我一样，他们对独立有着强烈的渴望，面对闯荡也多少有些忐忑。和你我不同，他们是16位盲人大学生。看视频，了解他们的故事。
        </p>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video controls="controls" poster={pic} preload="metadata">
          <source src="https://cdn.cnbj1.fds.api.mi-img.com/h5-base/assets/summer.mp4" type="video/mp4" />
        </video>
        <p>
          未来，小米将持续完善无障碍体验，让全球每个人都能享受科技带来的美好生活。
        </p>
      </div>
    </div>
  );
};

export default Summer;
