import xmlisten0 from '../../assets/img/homepage/camera.png';
import xmlisten1 from '../../assets/img/homepage/phone.png';
import xmlisten2 from '../../assets/img/homepage/hear.png';
import xmlisten3 from '../../assets/img/homepage/writing.png';

const data = {
  top: {
    text: '跨越数字鸿沟，实现信息平等',
    title: '让全球每一个人都能享受科技带来的美好生活',
    content:
      '以计算机与互联网为核心的信息技术将我们带入了一个信息爆炸的时代，为残障群体无形之中树立起一堵信息壁垒。智能终端成为社会关系网中的重要枢纽，这些终端提供的信息无障碍服务的好坏，将直接决定残障群体通往移动互联网的道路是否通畅。',
  },
  middle: {
    middleTop: {
      text: '“没有我们的参与，请不要替我们做决定”',
      title: '坚持与用户做朋友',
      content:
        '残疾人国际在1981年成立时确立的口号“没有我们的参与，请不要替我们做决定”（Nothing About Us Without Us）。我们坚持与用户交朋友。需求从来都不是凭空想象，所有需求从朋友处来，再到朋友处去。为了能够得到最真实的反馈，我们保持与朋友的密切沟通，第一时间反馈，最高优先级支持。',
    },

    googleSupport: [
      {
        title: '全面支持Google无障碍服务 因地制宜地提供定制化需求',
        introduction: '基于系统的全面基础功能支持，增加针对性辅助操作',
      },
      {
        title: '40多个贴心的主动场景提示',
        introduction:
          '关注用户日常使用场景，对每个场景进行主动提示。比如屏幕锁定时主动提示屏幕已关闭，屏幕开启后主动提示时间与上滑解锁操作；日历增加节日提醒功能，不再错过任何一个重要的时刻；指南针对方向进行主动播报，前进的方向有了保障；计算器对计算结果进行主动提示，计算过程更加高效......',
      },
      {
        title: '无缝的操作衔接',
        introduction:
          ' 1.系统在普通模式下，接听按钮会不停的跳动，视觉障碍用户很难捕捉到。开启TalkBack模式后，接听按钮不再跳动，焦点自动选中在接听按钮上，双击屏幕即可达到接听效果。 2.将图片验证码，直接转成语音播报的方式，告知用户。辅助视觉障碍用户无障碍地完成输入验证码的操作。',
      },
      {
        adj_one: '精准的',
        title_one: '色彩矫正',
        introduction_one:
          '在颜色的识别上，针对红绿区分与黄蓝区分有障碍的用户群体，提供精准的色彩校正。',
        adj_two: '便捷的',
        title_two: '无障碍功能菜单',
        introduction_two:
          '支持定制无障碍功能菜单，为用户提供包括小爱同学、电源开关、音量调节、通知管理等一系列便捷的功能直达。',
      },
      {
        title: '贴近生活的手机万能遥控器',
        introduction:
          '日常生活场景，传统的遥控操作无任何反馈，对设备的控制不符合视障群体的需求。系统提供万能遥控器，能够对每一步操作都有即时的响应与反馈，让视障用户轻松操作各种红外设备。 例如：对空调页面操作的实时提醒即用户每按下一个按钮，都会以语音形式给予反馈，告知当前空调的状态，“当前温度29度”。当用户按下温度减按钮时，告知当前空调的状态，“当前温度已调整至25度”。除此之外，对于制冷/制热等模式的切换、风速、风向等状态的反馈，万能遥控器全都支持。',
      },
      {
        title: '全面适配的TalkBack',
        introduction:
          '1.在开机引导首页，双指长按屏幕10s 2.系统设置-更多设置-无障碍-TalkBack 3.按键直达音量键+-同时按住3s 4.唤醒小爱，对小爱说“打开TalkBack”',
      },
    ],
    xmListen: [
      {
        id: 0,
        title: '与众不同的相机语音字幕',
        introduction:
          '相机录制有声视频，视频的有声信息在传递给听障用户时存在障碍。相机支持语音字幕的实时转录，将所有声音信息转成文字信息，以字幕的形式呈现给用户。带来与众不同的体验。',
        src: xmlisten0,
        alt: '图片 相机语音字幕功能页面',
      },
      {
        id: 1,
        title: '智能的AI电话助理',
        introduction:
          'AI电话助理为您在通话中将对方的语音识别转化成文字，您只需要轻点智能生成的答复语，或主动编辑文本信息，发送即可完成文字转成语音，将您所表达的内容传达给对方，进行无障碍的沟通，信息的场地将不再成为困难。',
        src: xmlisten1,
        alt: '图片 智能Ai电话助理功能页面',
        specialStyle: 'moreRight',
      },
      {
        id: 2,
        title: '细腻的听感调节',
        introduction:
          '通过音频的播放与用户给予的实时反馈，听感调节为每个用户定制专属的听感。为您的听力保驾护航，为您的听感带来细腻的享受！',
        src: xmlisten2,
        alt: '图片 听感调节定制后的页面',
        specialStyle: 'moreLeft',
      },
      {
        id: 3,
        title: '文字交互的小爱同学',
        introduction:
          '对于获取声音信息、传递声音信息有障碍的聋哑用户群体，小爱同学支持文字交互的方式，更懂您的需求！您只需要长按小爱同学对话按钮，便可呼出键盘用文字的方式和小爱同学进行交互。',
        src: xmlisten3,
        alt: '图片 通过文字和小爱同学交互询问天气',
      },
    ],
  },
};

export default data;
