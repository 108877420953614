import React from 'react';
import Suggestion from './suggestion/index';
import Special from './special/index';
import Union from './union/index';
import Summer from './summer/index';
import Dialog from './dialog/index';
import HelpTheDisabled from './helpTheDisabled/index';
import Infinity from './infinity/index';

const Detail = ({ match }) => {
  const { id } = match.params;
  if (id === '1') return <Suggestion />;
  if (id === '2') {
    return <Special />;
  }
  if (id === '3') {
    return <Union />;
  }
  if (id === '4') {
    return <Summer />;
  }
  if (id === '5') {
    return <Dialog />;
  }
  if (id === '6') {
    return <HelpTheDisabled />;
  }
  if (id === '7') {
    return <Infinity />;
  }
}


export default Detail;
