/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React, { Component } from 'react';
import Header from '../../components/header/header';
import MiddleContent from './middleContent/middleContent';
import ConnectAndControl from './ConnectAndControl';
import style from './homepage.module.scss';

const data = require('./data.json');

class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className={style.wrapper}>
          <div className={style.top}>
            <Header color="dark" />
            <div className={style.topContent}>
              <p>{data.top.text}</p>
              <h2>{data.top.title}</h2>
              <div>{data.top.content}</div>
            </div>
          </div>
        </div>
        <MiddleContent />
        <ConnectAndControl />
      </>
    );
  }
}

export default Homepage;
